<template>
  <div>
    <div class="category-box winAndplayCategory">
      <div v-if="loading" class="overlay-loader">
        <div class="loader-spin">
          <div class="bounce1"></div>
          <div class="bounce2"></div>
          <div class="bounce3"></div>
        </div>
      </div>
      <div class="catgory_head">
        <div class="title">
          <h4>
            <img src="/static/images/cricket_ic.svg" class="titile_icon cricket_icon" />
            {{$t('cricketGames')}}
          </h4>
        </div>
        <!-- <div class="categorytab">
<img src="/static/images/right_arrow_ic.svg">
        </div>-->
      </div>

      <ul>
        <li>
          <div class="gamegrid" @click="gotoCricket91()">
            <div class="gameImgbox">
              <img src="../assets/img/cricket91.jpg" />
              <div class="title-block">
                <div class="text-title">Cricket91</div>
                <span class="palyer-counter">Play and Win</span>
              </div>
            </div>
          </div>
        </li>

        <li>
          <div class="gamegrid" @click="gotoCricketbaazi()">
            <div class="gameImgbox">
              <img src="../assets/img/cricketbazzi.jpg" />
              <div class="title-block">
                <div class="text-title">Cricketbaazi</div>
                <span class="palyer-counter">Play and Win</span>
              </div>
            </div>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: "WinAndPlayCategory",
  props: {},
  data() {
    return {
      spinnerLoading: false,
      loading: false
    };
  },

  computed: {},

  methods: {
    gotoCricket91() {
      this.loading = true;
      var __x = this.$cookie.get("X-Auth-Id");
      // console.log({ __x });
      location.href = ` http://cricket.money91.com/?xauthid=${__x}`;
    },
    gotoCricketbaazi() {
      this.loading = true;
      var __x = this.$cookie.get("X-Auth-Id");
      location.href = ` http://dev1.money91.com:5002/#/?xauthid=${__x}`;
    }
  }
};
</script>
<style>
</style>
